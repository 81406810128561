@import "../../../../css/mixins/media-queries.scss";
@import "../../../../css/variables.scss";

.calculator-history {
  position: absolute;
  top: 0;
  z-index: 2;
  background-color: #f0f0ef;
  margin: 0 8px 8px 8px;
  width: calc(100% - 2px);
  height: 100%;
  border: 1px solid #e0e0e0;
  overflow-y: auto;

  &.first-row {
    left: 100%;
  }

  &.second-row {
    right: 100%;
  }

  @include mobile {
    margin: 0;
    left: 0 !important;
    right: 0 !important;
    width: 100%;
  }

  &__actions {
    padding: 8px 8px 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 16px);

    svg {
      width: 35px;
      fill: $primary;
    }

    button {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }

  .calculator-previous-session {
    border-bottom: 1px solid $primary;
  }
}
