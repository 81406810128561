@import "../../../../css/variables.scss";
@import "../../../../css/mixins/media-queries.scss";

$dark-background: #e0e0e0;
$light-background: #fcfcfc;

.calculator-button {
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: 400;
  padding: 12px 6px;
  touch-action: manipulation;
  border: 0;
  border-top: 1px solid #f0f0ef;
  border-right: 1px solid #f0f0f0;
  border-radius: 0;
  outline: initial;

  &--dark {
    background-color: $dark-background;
    color: $primary;
  }

  &--light {
    background-color: $light-background;
    color: gray;
  }

  &--primary {
    background-color: $primary;
    color: $white;
  }

  &--dark,
  &--light {
    &:hover {
      background-color: lightgray !important;
    }
  }

  &--primary:hover {
    background-color: #3796ce !important;
  }

  @include mobile {
    &--dark:hover {
      background-color: $dark-background !important;
    }

    &--light:hover {
      background-color: $light-background !important;
    }

    &--primary:hover {
      background-color: $primary !important;
    }
  }
}
