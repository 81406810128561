@import "../../../../../css/variables.scss";

.calculator-session {
  .history-operation {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 12px 8px 0;
    flex-wrap: wrap;
    margin: 0 8px;
    border-top: 1px solid #e0e0e0b8;

    div {
      margin-right: 12px;
      font-size: 20px;
      color: gray;
      margin: 6px 12px 6px 0;

      &.operation-index {
        padding: 2px 10px;
        width: 34px;
        text-align: center;
        color: $primary;
        font-weight: bold;
      }

      &.operation-number {
        padding: 2px 10px;
        border: 1px solid lightgray;

        &:hover {
          border: 1px solid $primary;
          cursor: pointer;
          background-color: $primary;
          color: white;
        }
      }
    }
  }
}
