@import "../../../css/mixins/media-queries.scss";
@import "../../../css/variables.scss";

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  background-color: $primary;
  color: $white;
  min-height: 64px;
  max-height: 64px;
  border-bottom: 1px solid $background-color;

  &__title {
    font-size: 20px;
    font-weight: 500;
  }
}