@import "../../../css/mixins/media-queries.scss";
@import "../../../css/variables.scss";

.calculator-placeholder {
  flex-grow: 1;
  border: 4px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0ef;
  min-height: 450px;
  max-height: 500px;

  @include mobile {
    min-height: 150px;
  }

  .button {
    border-radius: 15px;
  }
}
