@import "../../css/mixins/media-queries.scss";
@import "../../css/variables.scss";

.button {
  padding: 10px 20px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $background-color;

  @include mobile {
    padding: 10px;
  }

  &__icon {
    width: 25px;
    height: 25px;
    margin: 0 2px;
    fill: $white;

    &--small {
      width: 15px;
      height: 15px;
      fill: $white;
    }
  }

  &--primary {
    background-color: $primary;
    color: $white;

    .button__icon, .button__icon--small {
      fill: $white;
    }

    &:hover {
      background-color: #3796ce !important;
    }
  }

  &--secondary {
    color: $primary;
    background-color: transparent;
    border: 1px solid $primary;

    .button__icon, .button__icon--small {
      fill: $primary;
    }
  }
}