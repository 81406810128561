body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E0E0E0;
}

* {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
