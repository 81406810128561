@import "../../../css/mixins/media-queries.scss";
@import "../../../css/variables.scss";

.calculator {
  flex-grow: 1;
  border: 4px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  background-color: #f0f0ef;
  max-height: 500px;
  min-height: 450px;
  position: relative;

  @include mobile {
    border: 0 !important;
    border-bottom: 4px solid #e0e0e0 !important;
    padding: 0 !important;
  }

  &:focus {
    outline-color: $primary;

    @include mobile {
      outline: initial;
    }
  }

  &__result {
    min-height: 15%;
    text-align: right;
    padding: 10px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    background-color: $background-color;
    position: relative;
    margin: 7px;
    border: 1px solid #e0e0e0;
    min-height: 70px;

    @include mobile {
      margin: 0;
      border: 0;
      min-height: 90px;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      position: absolute;
      top: 10px;
      left: 20px;
      height: calc(100% - 25px);
      min-width: 40px;

      & > div {
        margin-bottom: 5px;
      }

      .calculator-id {
        color: $primary;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        svg {
          fill: $primary;
          width: 20px;
        }
      }

      .delete-calculator {
        .button {
          padding: 10px;
          border: 1px solid $primary;
        }

        svg {
          cursor: pointer;
          fill: $white;
          width: 18px;
          height: 18px;

          @include mobile {
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    &__last-operation {
      font-size: 16px;
      min-height: 20px;
      height: 33%;
      color: gray;
      padding-left: 50px;
      word-break: break-all;
    }

    &__value {
      word-break: break-all;
      padding-left: 50px;
    }
  }

  .calculator-history-toggle {
    width: calc(100% - 15px);
    margin: 0 8px;
    height: 29px;
    padding: 2px 0;
    border: 1px solid #e0e0e0;
    outline: lightgray;
    cursor: pointer;

    svg {
      height: 25px;
      fill: $primary;
    }

    @include mobile {
      margin: 0;
      width: 100%;
    }
  }

  &__actions {
    width: calc(100% - 15px);
    padding: 0 8px 8px 8px;
    flex-grow: 1;
    display: flex;

    @include mobile {
      padding: 0;
      width: 100%;
      position: relative;
    }

    .column {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .calculator-icon {
      width: 30px;
      height: 30px;
      fill: #055889;
    }

    .share-result {
      display: flex;
      justify-content: center;
      align-items: center;

      @include mobile {
        font-size: 0.55em;
      }

      @include desktop-small {
        font-size: 0.55em;
      }

      svg {
        width: 25px;
        height: 25px;
        fill: $primary;

        @include mobile {
          width: 18px;
          height: 18px;
        }

        &:not(:first-of-type):not(:last-of-type) {
          margin: 0 8px;
          width: 15px;
          height: 15px;

          @include mobile {
            margin: 0;
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
}
