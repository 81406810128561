@import "../../css/mixins/media-queries.scss";

.calculator-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-width: 1200px;
    width: 100%;
    margin: 5px auto 0 auto;

    @include mobile {
      flex-direction: column;
    }

    .calculator {
      &__result {
        font-size: 20px;
      }

      &:not(:only-child) {
        max-width: calc(50% - 8px);
        min-width: calc(50% - 8px);

        @include desktop {
          .calculator__result {
            font-size: 15px;
          }
        }

        @include mobile {
          max-width: 100%;
          min-width: 100%;
        }
      }
    }
  }
}
