@import "../../../../css/variables.scss";

.result-share-dialog {
  padding: 16px;
  min-width: 250px;

  .arrow-down {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    svg {
      margin-bottom: 8px;
      margin-left: 8px;
      width: 20px;
      height: 25px;
      fill: $primary;
    }
  }

  .calculator-item {
    display: flex;
    font-size: 20px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
    padding: 4px 8px;
    border: 1px solid lightgray;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      fill: $primary;
    }

    input {
      flex-grow: 1;
      margin-left: 20px;
      background-color: transparent;
      border: 0;
      padding: 4px;
      font-size: 16px;
    }
  }

  .calculators-list {
    .calculator-item {
      &:hover {
        background-color: $primary;
        border: 1px solid $primary;

        &,
        input {
          color: $white;
          cursor: pointer;
        }

        svg {
          fill: $white;
        }
      }
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
