$mobile-width: 0;
$mobile-small-width: 360px;
$desktop-width-small: 800px;
$desktop-width: 1024px;

@mixin mobile {
  @media (min-width: #{$mobile-width}) and (max-width: #{$desktop-width-small}) {
    @content;
  }
}

@mixin mobile-small {
  @media (min-width: #{$mobile-width}) and (max-width: #{$mobile-small-width}) {
    @content;
  }
}

@mixin desktop-small {
  @media (min-width: #{$desktop-width-small + 1px}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width-small}) {
    @content;
  }
}
